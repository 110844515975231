import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import {
  DESKTOP_REELS_FRAME_HEIGHT,
  DESKTOP_REELS_FRAME_POSITION_X,
  DESKTOP_REELS_FRAME_POSITION_Y,
  DESKTOP_REELS_FRAME_WIDTH,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LEFT_LINES_NUMBERS_CONTAINER_POSITIONS,
  LINES_NUMBER_CONTAINER_HEIGHT,
  LINES_NUMBER_CONTAINER_WIDTH,
  MOBILE_REELS_FRAME_HEIGHT,
  MOBILE_REELS_FRAME_POSITION_X,
  MOBILE_REELS_FRAME_POSITION_Y,
  MOBILE_REELS_FRAME_WIDTH,
  RIGHT_LINES_NUMBERS_CONTAINER,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public linesContainer: PIXI.Container;

  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: PIXI.Sprite;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.y = 75;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        this.slotsContainer.x,
        this.slotsContainer.y + SLOT_HEIGHT,
        SLOTS_CONTAINER_WIDTH,
        SLOTS_CONTAINER_HEIGHT,
      )
      .endFill();
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    if (SHOW_LINES_NUMBER_CONTAINER) {
      this.leftLinesContainer = new PIXI.Container();
      this.leftLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.leftLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.rightLinesContainer = new PIXI.Container();
      this.rightLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.rightLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.initLinesNumberContainers();
    }
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.linesContainer = props.linesContainer;
    this.tintContainer = props.tintContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.linesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.miniPayTableContainer);
    this.addChild(this.slotsContainer);
    this.frame = isMobile ? this.initMobileReelsFrame() : this.initDesktopReelsFrame();
    this.addChild(this.frame);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
  }

  private initDesktopReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    const ratio = frame.height / frame.width;
    frame.height = DESKTOP_REELS_FRAME_HEIGHT * ratio;
    frame.width = DESKTOP_REELS_FRAME_WIDTH;
    frame.y = DESKTOP_REELS_FRAME_POSITION_Y;
    frame.x = DESKTOP_REELS_FRAME_POSITION_X;
    return frame;
  }

  private initMobileReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frameMobile));
    const ratio = frame.height / frame.width;
    frame.height = MOBILE_REELS_FRAME_HEIGHT * ratio;
    frame.width = MOBILE_REELS_FRAME_WIDTH;
    frame.y = MOBILE_REELS_FRAME_POSITION_Y;
    frame.x = MOBILE_REELS_FRAME_POSITION_X;
    return frame;
  }

  public initLinesNumberContainers(): void {
    LEFT_LINES_NUMBERS_CONTAINER_POSITIONS.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.leftLinesContainer!.addChild(sprite);
    });
    this.leftLinesContainer!.x = 0;
    this.addChild(this.leftLinesContainer!);

    RIGHT_LINES_NUMBERS_CONTAINER.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.rightLinesContainer!.addChild(sprite);
    });
    this.rightLinesContainer!.x = SLOTS_CONTAINER_WIDTH + LINES_NUMBER_CONTAINER_WIDTH;
    this.addChild(this.rightLinesContainer!);
  }

  private resize(width: number, height: number): void {
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        this.parent.x,
        this.parent.y + height * 0.125,
        width,
        width * (SLOTS_CONTAINER_HEIGHT / SLOTS_CONTAINER_WIDTH),
      )
      .endFill();
    this.scale.set(
      width / SLOTS_CONTAINER_WIDTH,
      (width * (SLOTS_CONTAINER_HEIGHT / SLOTS_CONTAINER_WIDTH)) / SLOTS_CONTAINER_HEIGHT,
    );
  }
}

export default GameView;
