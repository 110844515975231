import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  APPLICATION_FPS,
  REELS_AMOUNT,
  REEL_WIDTH,
  SHOW_ALL_LINES_ON_WIN,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT,
  WIN_SLOT_ANIMATION_DURATION,
  WIN_SLOT_ANIMATION_SCALE,
  eventManager,
} from '../config';
import { IWinLine, Icon, IconType, PayoffType } from '../d';
import Slot from '../slot/slot';

class WinSlotsContainer extends ViewContainer {
  private winSlotsContainer: ViewContainer[];

  private slotsContainer: Slot[][];

  public animation: AnimationChain | null = null;

  public loopAnimation: Animation | null = null;

  constructor() {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.winSlotsContainer = [];
    this.slotsContainer = [];
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const slotsArray = [];
      const container = new ViewContainer();
      container.width = SLOT_WIDTH;
      container.height = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
      container.x = i * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
      for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
        const slot = new Slot(j, {
          id: SlotId.A,
          payoffType: PayoffType.LTR,
          type: IconType.REGULAR,
        });
        slot.anchor.set(0.5);
        slot.x += SLOT_WIDTH / 2;
        slot.y += SLOT_HEIGHT / 2;
        slot.visible = false;
        slotsArray.push(slot);
        container.addChild(slot);
      }
      this.slotsContainer.push(slotsArray);
      this.winSlotsContainer.push(container);
      this.addChild(container);
    }
  }

  private onStartWinAnimation(nextResult: ISettledBet, isTurboSpin: boolean) {
    this.showWin(nextResult, isTurboSpin);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.loopAnimation?.skip();
  }

  public highlightSlots(slots: number[], spinResult: Icon[], isTurboSpin: boolean | undefined): AnimationGroup {
    const animationGroup = new AnimationGroup({});
    slots.forEach((slotId) => {
      const slot = this.slotsContainer[slotId % 5][Math.floor(slotId / 5)];
      slot.texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[slotId].id]);
      slot.visible = false;
      const sheet = this.getSlotAnimationSheet(spinResult[slotId].id);
      if (sheet) {
        animationGroup.addAnimation(this.createSlotSpriteAnimation(sheet, slotId, isTurboSpin));
      } else {
        animationGroup.addAnimation(this.createSlotScaleAnimation(slot, isTurboSpin));
        animationGroup.addOnStart(() => {
          slot.visible = true;
        });
      }
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    animationGroup.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    return animationGroup;
  }

  private getSlotAnimationSheet(slotId: SlotId): PIXI.Spritesheet | undefined {
    if (_.isEmpty(MAPPED_SYMBOLS_ANIMATIONS[slotId])) return undefined;

    return _.get(PIXI.Loader.shared.resources, MAPPED_SYMBOLS_ANIMATIONS[slotId]).spritesheet;
  }

  private createSlotSpriteAnimation(
    sheet: PIXI.Spritesheet | undefined,
    id: number,
    isTurboSpin: boolean | undefined,
  ): Animation {
    const animatedSprite = new SpriteAnimation({}, Object.values(sheet?.textures));
    animatedSprite.spriteAnimation.animationSpeed =
      (isTurboSpin
        ? animatedSprite.spriteAnimation.totalFrames * TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT
        : animatedSprite.spriteAnimation.totalFrames) / APPLICATION_FPS;
    animatedSprite.spriteAnimation.x = SLOT_WIDTH / 2;
    animatedSprite.spriteAnimation.y = SLOT_HEIGHT * Math.floor(id / 5) + SLOT_HEIGHT / 2;
    const container = this.winSlotsContainer[id % 5];
    animatedSprite.addOnStart(() => {
      container.addChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnSkip(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnComplete(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    return animatedSprite;
  }

  private createSlotScaleAnimation(sprite: PIXI.Sprite, isTurboSpin: boolean | undefined): AnimationGroup {
    const animation: AnimationGroup = new AnimationGroup({});
    const { x, y } = sprite.scale;
    const animationChainX = new AnimationChain();
    const animationDuration = isTurboSpin ? WIN_SLOT_ANIMATION_DURATION / 4 : WIN_SLOT_ANIMATION_DURATION / 2;
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x,
        target: x * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x * WIN_SLOT_ANIMATION_SCALE,
        target: x,
        duration: animationDuration,
      }),
    );
    const animationChainY = new AnimationChain();
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y,
        target: y * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y * WIN_SLOT_ANIMATION_SCALE,
        target: y,
        duration: animationDuration,
      }),
    );
    animation.addAnimation(animationChainX);
    animation.addAnimation(animationChainY);
    return animation;
  }

  public hideAllSlots(): void {
    for (let i = 0; i < this.slotsContainer.length; i++) {
      for (let j = 0; j < this.slotsContainer[i].length; j++) {
        this.slotsContainer[i][j].visible = false;
        this.slotsContainer[i][j].scale.set(1, 1);
      }
    }
  }

  private showWin(nextResult: ISettledBet, isTurboSpin: boolean | undefined): void {
    const { paylines, spinResult } = nextResult.round;
    this.animation = new AnimationChain();
    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });
    const allSlotsHighlight = this.highlightSlots(Array.from(set), spinResult, isTurboSpin);
    this.animation.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_TINT, true);
    });
    allSlotsHighlight.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
    });
    allSlotsHighlight.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      this.hideAllSlots();
    });
    allSlotsHighlight.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (SHOW_ALL_LINES_ON_WIN) this.animation.appendAnimation(allSlotsHighlight);
    const animationChain = this.createHighlightChainAnimation(paylines, spinResult, isTurboSpin, false);
    this.loopAnimation = this.createHighlightChainAnimation(paylines, spinResult, isTurboSpin, true);
    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (paylines.length > 1) this.animation.appendAnimation(animationChain);
    animationChain.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES);
      eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES);
    });
    this.animation.addOnComplete(() => this.loopAnimation?.start());
    this.animation.start();
  }

  public createHighlightChainAnimation(
    paylines: IWinLine[],
    spinResult: Icon[],
    isTurboSpin: boolean | undefined,
    isLoop: boolean,
  ): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions, spinResult, isTurboSpin);
      chain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
      });
      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      });
      chain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      });
      animationChain!.appendAnimation(chain);
    });
    return animationChain;
  }
}

export default WinSlotsContainer;
