export enum ResourceTypes {
  reelLogo = 'reelLogo',
  reelsLight = 'reelsLight',
  frameMobile = 'frameMobile',
  frame = 'frame',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  cherry = 'cherry',
  grape = 'grape',
  lemon = 'lemon',
  orange = 'orange',
  peach = 'peach',
  scatter = 'scatter',
  seven = 'seven',
  watermelon = 'watermelon',
  wild = 'wild',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  introBgMobile = 'introBgMobile',
  introBg = 'introBg',
  introReel = 'introReel',
  slotBg = 'slotBg',
}
