import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

export const Textures = {
  [ResourceTypes.seven]: Resources.getSource(ResourceTypes.seven),
  [ResourceTypes.grape]: Resources.getSource(ResourceTypes.grape),
  [ResourceTypes.orange]: Resources.getSource(ResourceTypes.orange),
  [ResourceTypes.cherry]: Resources.getSource(ResourceTypes.cherry),
  [ResourceTypes.lemon]: Resources.getSource(ResourceTypes.lemon),
  [ResourceTypes.scatter]: Resources.getSource(ResourceTypes.scatter),
  [ResourceTypes.peach]: Resources.getSource(ResourceTypes.peach),
  [ResourceTypes.watermelon]: Resources.getSource(ResourceTypes.watermelon),
  [ResourceTypes.wild]: Resources.getSource(ResourceTypes.wild),
};

export const MAPPED_SYMBOLS = {
  A: ResourceTypes.seven,
  B: ResourceTypes.grape,
  C: ResourceTypes.watermelon,
  D: ResourceTypes.peach,
  E: ResourceTypes.lemon,
  F: ResourceTypes.orange,
  G: ResourceTypes.cherry,
  WL: ResourceTypes.wild,
  SC1: ResourceTypes.scatter,
};

export const MAPPED_SYMBOLS_ANIMATIONS = {
  A: 'sevenAnimation',
  B: 'grapeAnimation',
  C: 'watermelonAnimation',
  D: 'peachAnimation',
  E: 'lemonAnimation',
  F: 'orangeAnimation',
  G: 'cherryAnimation',
  WL: 'wildAnimation',
  SC1: 'scatterAnimation',
};

export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.seven, src: Resources.getSource(ResourceTypes.seven) },
  { name: ResourceTypes.grape, src: Resources.getSource(ResourceTypes.grape) },
  {
    name: ResourceTypes.watermelon,
    src: Resources.getSource(ResourceTypes.watermelon),
  },
  { name: ResourceTypes.peach, src: Resources.getSource(ResourceTypes.peach) },
  { name: ResourceTypes.lemon, src: Resources.getSource(ResourceTypes.lemon) },
  {
    name: ResourceTypes.orange,
    src: Resources.getSource(ResourceTypes.orange),
  },
  {
    name: ResourceTypes.cherry,
    src: Resources.getSource(ResourceTypes.cherry),
  },
  { name: ResourceTypes.wild, src: Resources.getSource(ResourceTypes.wild) },
  {
    name: ResourceTypes.scatter,
    src: Resources.getSource(ResourceTypes.scatter),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.frameMobile,
    src: Resources.getSource(ResourceTypes.frameMobile),
  },
  {
    name: ResourceTypes.shortLine,
    src: Resources.getSource(ResourceTypes.shortLine),
  },
  {
    name: ResourceTypes.mediumLine,
    src: Resources.getSource(ResourceTypes.mediumLine),
  },
  {
    name: ResourceTypes.largeLine,
    src: Resources.getSource(ResourceTypes.largeLine),
  },
  {
    name: ResourceTypes.reelsLight,
    src: Resources.getSource(ResourceTypes.reelsLight),
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  { name: 'cherryAnimation', src: '/animations/cherry/cherryAnimation.json' },
  { name: 'lemonAnimation', src: '/animations/lemon/lemonAnimation.json' },
  {
    name: 'watermelonAnimation',
    src: '/animations/watermelon/watermelonAnimation.json',
  },
  {
    name: 'grapeAnimation',
    src: '/animations/grape/grapeAnimation.json',
  },
  {
    name: 'orangeAnimation',
    src: '/animations/orange/orangeAnimation.json',
  },
  {
    name: 'peachAnimation',
    src: '/animations/peach/peachAnimation.json',
  },
  {
    name: 'wildAnimation',
    src: '/animations/wild/wildAnimation.json',
  },
  {
    name: 'scatterAnimation',
    src: '/animations/scatter/scatterAnimation.json',
  },
  {
    name: 'sevenAnimation',
    src: '/animations/seven/sevenAnimation.json',
  },
  {
    name: 'coinsAnimation',
    src: '/animations/coins/coins.json',
  },
  { name: 'reelLogo', src: Resources.getSource(ResourceTypes.reelLogo) },
];
