import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_WIDTH } from '../config';

class GameTitle extends ViewContainer {
  private logo: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelLogo));

  constructor() {
    super();
    this.logo.width = SLOTS_CONTAINER_WIDTH - 250;
    this.logo.height = 97;
    this.logo.x = (SLOTS_CONTAINER_WIDTH - this.logo.width) / 2;
    this.logo.y = 0;
    this.addChild(this.logo);
  }
}

export default GameTitle;
