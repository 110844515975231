import { useQuery } from '@apollo/client';
import * as _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Input, RangeSlider } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { getBetAmountGql, getSlotConfigGql, getUserGql, setBetAmount } from '../../gql';
import { normalizeCoins, wrap } from '../../utils';
import { getBetsSetting } from '../../utils/fromFragment';

import styles from './betSettings.module.scss';
import { IBetSettingsProps } from './d';

const BetSettings: React.FC<IBetSettingsProps> = (props) => {
  const { className = '', handleClose = () => {} } = props;
  const { t } = useTranslation();
  const { data, client } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: slotData } = useQuery(getSlotConfigGql);
  const linesAmount = slotData.slotConfig.lines.length;
  const betSettings = getBetsSetting();

  const {
    user: {
      balance: { currency },
    },
  } = client.readQuery({
    query: getUserGql,
  });

  const changeBet = (v: number) => {
    if (betSettings.maxBet === v) {
      AudioHowl.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }

    setBetAmount(v);
  };

  const changeSliderBet = (v: number) => {
    if (betSettings.maxBet === betSettings.bets[v - 1]) {
      AudioHowl.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
    setBetAmount(betSettings.bets[v - 1] || 0);
  };

  const handleClosePopup = () => {
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    handleClose();
  };

  return (
    <div className={`${styles['bet-settings']} ${className}`}>
      <Input
        className={`input ${styles.input}`}
        type="text"
        value={formatNumber({ currency, value: normalizeCoins((data?.betAmount || 0) * linesAmount) })}
        onChange={() => {}}
        readOnly
      />
      <RangeSlider
        className={`range-slider ${styles['range-slider']}`}
        min={1}
        max={betSettings.bets.length}
        value={_.findIndex(betSettings.bets, (bet) => bet === data?.betAmount) + 1}
        onChange={changeSliderBet}
      />
      <div className={styles['fast-bet']}>
        {betSettings.quick.map((item) => (
          <div key={item} className={styles['fast-bet__item']}>
            <Button
              intent="betSettingsBtn"
              className={styles.btn}
              isActive={data?.betAmount === item}
              title={formatNumber({ currency, value: normalizeCoins(item * linesAmount), showCurrency: true })}
              onClick={wrap(changeBet, item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BetSettings;
