import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const AutoPlay: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.autoplay}>
      <h1 className={styles.title}>{t('infoAutoPlayTitle')}</h1>
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay1'),
        }}
      />
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay2'),
        }}
      />
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay3'),
        }}
      />
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay4'),
        }}
      />
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay5'),
        }}
      />
    </div>
  );
};

export default AutoPlay;
