import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS } from '../../config';
import { SLOT_HEIGHT, SLOT_WIDTH } from '../config';
import { Icon } from '../d';

class Slot extends PIXI.Sprite {
  private id: number;

  public icon: Icon;

  constructor(id: number, icon: Icon) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[icon.id]));
    this.id = id;
    this.icon = icon;
    this.width = SLOT_WIDTH;
    this.height = SLOT_HEIGHT;
    this.y = SLOT_HEIGHT * id;
  }

  public changeTexture(icon: Icon): void {
    this.icon = icon;
    this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[icon.id]);
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }
}

export default Slot;
