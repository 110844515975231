import { gql } from '@apollo/client';

import { BET, BET_RESULT, BONUS, LINE_SET, PAYLINE, REEL_SET, USER_BALANCE, USER_BONUS } from './fragments';

export const authGql = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
    }
  }
`;

export const placeBetGql = gql`
  ${BET}
  ${REEL_SET}
  ${LINE_SET}
  ${BONUS}
  ${BET_RESULT}
  ${PAYLINE}
  ${USER_BALANCE}
  ${USER_BONUS}
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
      bet {
        ...bet
        reelSet {
          ...reelSet
        }
        lineSet {
          ...lineSet
        }
        userBonus {
          bonus {
            ...bonus
          }
        }
        result {
          ...betResult
        }
      }
      paylines {
        ...payline
      }
      balance {
        placed {
          ...userBalance
        }
        settled {
          ...userBalance
        }
      }
      rewards {
        ... on BetBonusReward {
          userBonusId
          userBonus {
            ...userBonus
            bonus {
              ...bonus
            }
          }
        }
        ... on BetCoinReward {
          amount
        }
      }
    }
  }
`;

export const buyBonusGql = gql`
  ${USER_BONUS}
  mutation buyBonus($input: BuyBonusesInput!) {
    buyBonus(input: $input) {
      ...bonus
    }
  }
`;
