import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { TweenProperties } from '../animations/d';
import SpinAnimation from '../animations/spin';
import Tween from '../animations/tween';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_BLUR_VALUE,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_ROLLING_FORMULA,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_ROLLING_DURATION,
  FAKE_ROLLING_SLOTS,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TIMEOUT_ERROR_MESSAGE,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_BLUR_VALUE,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Icon } from '../d';
import Slot from '../slot/slot';

import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: Icon[];

  public container: PIXI.Container;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public blur: PIXI.filters.BlurFilter = new PIXI.filters.BlurFilter();

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public prolongTime = 0;

  public size: number;

  constructor(id: number, data: Icon[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new PIXI.Container();
    this.container.width = SLOT_WIDTH;
    this.container.x = id * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.container.y = 0;
    this.createSlots();
    this.blur.blurX = 0;
    this.blur.blurY = 0;
    this.container.filters = [this.blur];
    this.position = this.size - startPosition;
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
  }

  private onAnticipationStart(): void {
    _.forEach(this.slots, (slot) => {
      if (slot.icon.id !== SlotId.SC1) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    // this.position = Math.floor(Math.random() * this.data.length);
    for (let i = 0; i < this.data.length; i++) {
      const slot = new Slot(i, this.data[i % this.data.length]);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingTime = isTurboSpin
      ? TURBO_REEL_ROLLING_DURATION + this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL
      : BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
    const target = this.position + INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP + this.id * 5;

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + FAKE_ROLLING_SLOTS,
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(previousState: ReelState, newState: ReelState): void {
    if (this.isPlaySoundOnStop) {
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStop, stopPrev: true });
      this.isPlaySoundOnStop = false;
    }
  }

  private onReelIdle(previousState: ReelState, newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Array<Slot> {
    const slots: Array<Slot> = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    slots.push(this.slots[top]);
    slots.push(this.slots[middle]);
    slots.push(this.slots[bottom]);
    return slots;
  }

  private onReelRolling(previousState: ReelState, newState: ReelState): void {}

  private onReelStarting(previousState: ReelState, newState: ReelState): void {
    this.prolongTime = 0;
  }

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    if (this.state === ReelState.ROLLING) {
      this.blur.blurY = this.isTurboSpin ? TURBO_REEL_BLUR_VALUE : BASE_REEL_BLUR_VALUE;
    } else {
      this.blur.blurY = 0;
    }
    this.previousPosition = this.position;

    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j];
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT - SLOT_HEIGHT;
    }
  }
}

export default Reel;
