import { useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Carousel, Toggle } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import {
  configGql,
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setProgress,
  setSkipIntroScreen,
  setSoundValue,
} from '../../gql';
import { IConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import Resources from '../../utils/resources';

import styles from './introScreen.module.scss';

const IntroScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isShowContent, setShowContent] = useState(setSkipIntroScreen());
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn } = data!;
  const handlePlay = () => {
    const soundToPlay = [
      { type: ISongs.Music_Theme_BaseGame_Loop_base },
      { type: ISongs.Music_Theme_BaseGame_Loop_melo, volume: 0 },
    ];

    AudioHowl.restrictionChangedOnIntroScreen = true;
    if (!AudioHowl.isInitialized) {
      eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
    }
    AudioHowl.changeRestriction(
      false,
      soundToPlay,
      () => setIsSoundLoading(true),
      () => setIsShowSoundToast(false),
    );

    setProgress({ ...setProgress(), wasLoaded: true });

    setTimeout(() => {
      if (isShowContent === setSkipIntroScreen()) {
        setSkipIntroScreen(isShowContent);
      }
      setShowContent((p) => !p);
    });
  };

  useEffect(() => {
    eventManager.once(EventTypes.SKIP_INTRO_SCREEN, () => setShowContent(setSkipIntroScreen()));
  }, []);

  if (isShowContent) return null;

  return (
    <div className={styles.intro}>
      <Carousel dots={false} className={styles.carousel}>
        <div className={styles.slide}>
          <div className={styles.demo}>
            <img draggable="false" src={Resources.getSource(ResourceTypes.introReel)} alt="" />
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: t('introTitle') }} />
          </div>
        </div>
      </Carousel>
      <button className={styles.playBtn} onClick={handlePlay}>
        <img
          draggable="false"
          className={styles.bigger}
          src={Resources.getSource(ResourceTypes.buttonOk)}
          alt="infoBtnBetAmountOk"
        />
      </button>

      <div className={styles['intro-bottom']}>
        <div className={styles['intro-bottom__col']}>
          <Toggle
            className={styles.toggle}
            checked={!!isSoundOn}
            id="sound_on"
            onChange={() => {
              AudioHowl.setSoundState(!isSoundOn);
              setIsSoundOn(!isSoundOn);
              setSoundValue(!isSoundOn ? 1 : 0);
            }}
          />
          <span className={styles.label}>{t('sound')}</span>
        </div>
        <div className={styles['intro-bottom__col']}>
          <Toggle
            className={styles.toggle}
            checked={setSkipIntroScreen()}
            id="dont_show"
            onChange={() => setSkipIntroScreen(!setSkipIntroScreen())}
          />
          <span className={styles.label}>{t('show')}</span>
        </div>
      </div>
    </div>
  );
};

export default IntroScreen;
