import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, audioSpriteVolume, config } from '../../config';
import { EventTypes, GraphQLErrorsType, ISettledBet2, IUserBalance } from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  placeBetGql,
  replayBetGql,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinValue,
  setGameHistory,
  setIsAutoSpins,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setReplayBet,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setWinAmount,
  stressfulGql,
} from '../../gql';
import { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { apiV2ToApiV1PlaceBet, normalizeCoins, saveReelPosition } from '../../utils';
import { getLineSets } from '../../utils/fromFragment';

import { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { lines } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const balanceAmount = userData?.user.balance.amount;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const fadeOutCallback = () => {
    AudioHowl.fadeOut(3000, ISongs.Music_Theme_BaseGame_Loop_melo);
  };

  const betCompleteCallback = (placeBet: ISettledBet2, client: ApolloClient<any>): void => {
    eventManager.emit('placeBetCompleted');

    const oldPlaceBet = apiV2ToApiV1PlaceBet(placeBet);

    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: placeBet.balance.placed,
        },
      },
    });
    SlotMachine.getInstance().setResult(oldPlaceBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      const win = (placeBet.bet.result.winCoinAmount * setCoinValue().variants[0]) / 100;
      setWinAmount(win);
      // const arr = setGameHistory().slice(1);
      // setGameHistory([...arr, !!win]);
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
      setGameHistory(lastThreeSpins);

      if (placeBet.bet.coinAmount * lines.length * 2 <= win && !thirdWinInRow) {
        AudioHowl.fadeIn(3000, ISongs.Music_Theme_BaseGame_Loop_melo, audioSpriteVolume.Music_Theme_BaseGame_Loop_melo);
      }
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet2 }, { input: IPlaceBetInput }>(placeBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError(error) {
      eventManager.emit('placeBetCompleted');
    },
  });
  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet2 }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError(error) {
      eventManager.emit('placeBetCompleted');
    },
  });

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      setWinAmount(0);
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          fnGet({
            variables: {
              input: {
                coinAmount: dataBet?.betAmount || 1,
                coinValue: setCoinValue().variants[0],
                ...getLineSets(),
              },
            },
          });
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioHowl.isRestricted) {
          AudioHowl.changeRestriction(false, [
            { type: ISongs.Music_Theme_BaseGame_Loop_base },
            { type: ISongs.Music_Theme_BaseGame_Loop_melo, volume: 0 },
          ]);
        }
      }

      if (AudioHowl.isRestricted) {
        const soundToPlay = [
          { type: ISongs.Music_Theme_BaseGame_Loop_base },
          { type: ISongs.Music_Theme_BaseGame_Loop_melo, volume: 0 },
        ];

        AudioHowl.changeRestriction(
          false,
          soundToPlay,
          () => setIsSoundLoading(true),
          () => setIsShowSoundToast(false),
        );
      }
    },
    [client, dataBet, fnGet, getReplayBet],
  );
  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      if (
        setAutoSpinsLeft() === 0 ||
        (setIsStopOnAnyWin() && setWinAmount() > 0) ||
        (setIsStopOnWinExceeds() && setWinAmount() >= normalizeCoins(setStopOnWinExceeds())) ||
        (setIsStopOnBalanceIncrease() &&
          (balanceAmount || 0) / 100 &&
          normalizeCoins(setStopOnBalanceIncrease()) <= (balanceAmount || 0) / 100 - setAutoSpinsStartBalance()) ||
        (setIsStopOnBalanceDecrease() &&
          (balanceAmount || 0) / 100 &&
          normalizeCoins(setStopOnBalanceDecrease()) <= setAutoSpinsStartBalance() - (balanceAmount || 0) / 100)
      ) {
        setIsAutoSpins(false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  const onSpinButtonClick = useCallback(() => {
    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();
        if (isAutoSpins) {
          if (data?.isEnabledSpaceSpin && isAutoSpins) checkAutoSpinSettings();
          return;
        }
        if (data?.isEnabledSpaceSpin && progress?.wasLoaded) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      data?.isEnabledSpaceSpin,
      isAutoSpins,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => {
      window.removeEventListener('keydown', useHandleSpaceSpin);
    };
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    if (isAutoSpins) {
      onSpin(isTurboSpin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoSpins, onSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes, (acc, item) => acc && item);
    const stop = !_.reduce(winThreeTimes, (acc, item) => acc || item);

    if (play) {
      AudioHowl.fadeIn(500, ISongs.Music_Theme_BaseGame_Loop_melo);
    }

    if (stop) {
      AudioHowl.fadeOut(3000, ISongs.Music_Theme_BaseGame_Loop_melo);
    } else {
      timeout = setTimeout(fadeOutCallback, 30000);
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (dataSlotStopped?.isSlotStopped) {
      id = setTimeout(() => {
        checkAutoSpinSettings();
      }, config.autoplay.timeOut);
    }
    return () => clearTimeout(id);
  }, [checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
