import * as PIXI from 'pixi.js';

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 80,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableNumberStyle = {
  fontSize: 16,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  leading: '',
  fill: '0xffffff',
};

export const miniPayTableTextStyle = {
  fontSize: 16,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  leading: '',
  fill: '0xf6e87e',
};

export const bottomContainerTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  fontWeight: 'bold',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};

export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winCounterStyle);

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
