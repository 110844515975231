import { GET_BET_SETTINGS, LINE_SET_ON_SLOT, client, setSlotConfig } from '../gql';
import { IBetSettings } from '../gql/d';

export const getBetsSetting = (): IBetSettings => {
  const { id } = setSlotConfig();
  const { clientSettings } = client.readFragment({
    id: `Slot:${id}`,
    fragment: GET_BET_SETTINGS,
  });

  return {
    minBet: clientSettings.coinAmounts.default[0] ?? 0,
    maxBet: clientSettings.coinAmounts.default[clientSettings.coinAmounts.default.length - 1] ?? 0,
    bets: clientSettings.coinAmounts.default,
    quick: clientSettings.coinAmounts.quick,
  };
};

export const getLineSets = (): { lineSetId: string; slotId: string } => {
  const { id } = setSlotConfig();
  const {
    lineSets: [{ id: lineSetId, slotId }],
  } = client.readFragment({
    id: `Slot:${id}`,
    fragment: LINE_SET_ON_SLOT,
  });

  return {
    lineSetId,
    slotId,
  };
};
