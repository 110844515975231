import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { IUserBalance } from '../../global';
import { getUserGql, setCoinValue } from '../../gql';
import { getBetsSetting } from '../../utils/fromFragment';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { minBet, maxBet } = getBetsSetting();

  const {
    user: { balance },
  } = userData!;

  // TODO to be removed
  const { currency } = balance;
  // eslint-disable-next-line no-underscore-dangle
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  return (
    <div className={styles.gameRules}>
      <h1 className={styles.title}>{t('infoGameRulesTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules1') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules2') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules3') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules4') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules5') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules6') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules7') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules8') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules9') }} />
        <div dangerouslySetInnerHTML={{ __html: t('infoGameRules10') }} />
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules11', {
              betAmount: formatNumber({ currency, value: (minBet * 25 * setCoinValue().variants[0]) / 100 }),
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules12', {
              betAmount: formatNumber({ currency, value: (maxBet * 25 * setCoinValue().variants[0]) / 100 }),
            }),
          }}
        />
      </div>
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules13', {
            rtp: '96.61%',
          }),
        }}
      />
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRulesVersion', {
            version,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
