import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinValue, setIsMiniPayTable, setSlotConfig } from '../../gql';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { Combo, Icon } from '../d';
import Slot from '../slot/slot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends PIXI.Container {
  private getSlotById: CallableFunction;

  private payTableContainers: PIXI.Container[];

  constructor(getSlotByIdFn: CallableFunction) {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.getSlotById = getSlotByIdFn;
    this.visible = setIsMiniPayTable();
    eventManager.addListener('disablePayTable', (isVisible: boolean) => (this.visible = isVisible));
    this.payTableContainers = [];
    this.createContainers();
  }

  private createContainers() {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT * REELS_AMOUNT; i++) {
      const slot: Slot = this.getSlotById(i);
      const { icon } = slot;
      const container = new PIXI.Container();
      const rect = new PIXI.Graphics();
      container.width = REEL_WIDTH;
      container.height = SLOT_HEIGHT;
      container.x = this.xPosition(i);
      container.y = this.yPosition(i);
      container.zIndex = 1;
      container.interactive = true;
      rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
      rect.drawRect(0, 0, SLOT_WIDTH, SLOT_HEIGHT);
      rect.alpha = 0;
      container.addChild(rect);
      container.on('mousedown', () => {
        eventManager.emit(EventTypes.SHOW_PAY_TABLE, i);
      });
      container.on('touchstart', () => eventManager.emit(EventTypes.SHOW_PAY_TABLE, i));
      const payTable = new MiniPayTable(i, icon, icon.combos);
      container.addChild(payTable);
      this.payTableContainers.push(container);
      this.addChild(container);
    }
  }

  private xPosition(i: number): number {
    const positionValue = REEL_WIDTH;
    if (i > 4 && i < 10) return (i - REELS_AMOUNT) * positionValue;
    if (i > 9) return (i - REELS_AMOUNT * 2) * positionValue;

    return i * positionValue;
  }

  private yPosition(i: number): number {
    if (i > 4 && i < 10) return SLOT_HEIGHT;
    if (i > 9) return SLOT_HEIGHT * 2;

    return 0;
  }

  private calcMultiplier(multiplier: number, id: SlotId): string {
    const { coinCode } = setSlotConfig();
    if (id === SlotId.SC1) {
      return formatNumber({
        currency: coinCode,
        value: (setBetAmount() * multiplier * setCoinValue().variants[0] * 25) / 100,
      });
    }
    return formatNumber({
      currency: coinCode,
      value: (setBetAmount() * multiplier * setCoinValue().variants[0]) / 100,
    });
  }

  private calcLineHeight(combosLength: number): number {
    if (combosLength < 4) return 20;
    return 20;
  }

  private calcTextYPosition(combosLength: number): number {
    if (combosLength < 4) return 45;
    return 35;
  }

  public setSpinResult(spinResult: Icon[]): void {
    spinResult.forEach((item, index: number) => {
      const { id, combos } = item;
      const container = this.payTableContainers[index];
      const payTable = container.children[1] as MiniPayTable;
      payTable.combos = _.cloneDeep(combos)?.reverse();
      const sprite = payTable.children[1] as PIXI.Sprite;
      const number = payTable.children[2] as PIXI.Text;
      const text = payTable.children[3] as PIXI.Text;
      sprite.texture = PIXI.Texture.from(MAPPED_SYMBOLS[id]);
      payTable.iconId = id;
      const combosLength = combos!.length;
      number.y = this.calcTextYPosition(combosLength);
      number.style.lineHeight = this.calcLineHeight(combosLength);
      number.text = payTable.combos?.reduce((acc: string, curr: Combo) => `${acc} ${curr.pattern}: \n`, '') || '';
      text.y = this.calcTextYPosition(combosLength);
      text.style.lineHeight = this.calcLineHeight(combosLength);
      text.text =
        payTable.combos?.reduce(
          (acc: string, curr: Combo) => `${acc} ${this.calcMultiplier(curr.multiplier, id)}\n`,
          '',
        ) || '';
    });
  }
}

export default MiniPayTableContainer;
