import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinValue, setIsMiniPayTable, setSlotConfig } from '../../gql';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  REEL_WIDTH,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
  miniPayTableNumberStyle,
  miniPayTableTextStyle,
} from '../config';
import { Combo, Combos, Icon } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private isLast: boolean;

  private betAmount: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect: PIXI.Graphics;

  public numbers: PIXI.Text;

  public text: PIXI.Text;

  public sprite: PIXI.Sprite;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.isLast = id === 4 || id === 9 || id === 14;
    this.x = this.isLast ? -(SLOT_WIDTH * 2 - REEL_WIDTH) : 5;
    this.y = 0;
    this.iconId = icon.id;
    this.width = SLOT_WIDTH * 2;
    this.height = SLOT_HEIGHT;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.betAmount = (setBetAmount() * 25 * setCoinValue().variants[0]) / 100;
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => this.showPayTable(i));
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_BET_AMOUNT, (betAmount: number) =>
      this.handleChangeBetAmount(betAmount),
    );
    // rect
    this.rect = new PIXI.Graphics();
    this.rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    this.rect.alpha = 0.75;
    this.rect.drawRoundedRect(
      this.isLast ? -5 : SLOT_WIDTH / 2,
      (SLOT_HEIGHT - PAY_TABLE_HEIGHT) / 2,
      PAY_TABLE_WIDTH,
      PAY_TABLE_HEIGHT,
      15,
    );
    this.rect.zIndex = 2;
    this.addChild(this.rect);
    // sprite
    this.sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[icon.id]));
    this.sprite.x = this.isLast ? SLOT_WIDTH - 5 : 0;
    this.sprite.y = 0;
    this.sprite.width = SLOT_WIDTH;
    this.sprite.height = SLOT_HEIGHT;
    this.sprite.zIndex = 3;
    this.addChild(this.sprite);
    this.numbers = new PIXI.Text(this.getCombosNumbers(), {
      lineHeight: this.calcLineHeight(),
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableNumberStyle,
    });
    this.numbers.x = this.isLast ? 20 : SLOT_WIDTH + 10;
    this.numbers.y = this.calcTextYPosition();
    this.numbers.zIndex = 3;
    this.addChild(this.numbers);
    this.text = new PIXI.Text(this.getCombos(), {
      lineHeight: this.calcLineHeight(),
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableTextStyle,
    });
    this.text.x = this.isLast ? 53 : SLOT_WIDTH + 43;
    this.text.y = this.calcTextYPosition();
    this.text.zIndex = 3;
    this.addChild(this.text);
  }

  private calcMultiplier(multiplier: number): string {
    const { coinCode } = setSlotConfig();
    if (this.iconId === 'SC1') return formatNumber({ currency: coinCode, value: this.betAmount * multiplier });
    return formatNumber({ currency: coinCode, value: (this.betAmount / 25) * multiplier });
  }

  private calcLineHeight(): number {
    if (this.combos!.length < 4) return 20;
    return 20;
  }

  private calcTextYPosition(): number {
    if (this.combos!.length < 4) return 45;
    return 35;
  }

  private getCombosNumbers(): string {
    return this.combos?.reduce((acc: string, curr: Combo) => `${acc} ${curr.pattern}: \n`, '') || '';
  }

  private getCombos(): string {
    return (
      this.combos?.reduce((acc: string, curr: Combo) => `${acc} ${this.calcMultiplier(curr.multiplier)}\n`, '') || ''
    );
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!setIsMiniPayTable() || this.iconId === SlotId.WL) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }

  private handleChangeBetAmount(betAmount: number): void {
    this.betAmount = (setBetAmount() * 25 * setCoinValue().variants[0]) / 100;
    this.text.text = this.getCombos();
  }
}

export default MiniPayTable;
