import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Grid, GridItem, RangeSlider, Toggle } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { IUserBalance } from '../../global';
import {
  getAutoSpinsSettingsGql,
  getBetAmountGql,
  getSlotConfigGql,
  getUserGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinValue,
  setIsAutoSpins,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnWinExceeds,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
} from '../../gql';
import { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import { AutoPlaySettingsInput } from './AutoPlaySettingsInput';
import styles from './autoPlaySettings.module.scss';
import { IAutoPlaySettingsProps } from './d';

const AutoPlaySettings: React.FC<IAutoPlaySettingsProps> = (props) => {
  const { t } = useTranslation();
  const { className = '', handleClose = () => {} } = props;
  const { data: slotData } = useQuery(getSlotConfigGql);

  const {
    clientSettings: { autoplay },
  } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const linesAmount = slotData.slotConfig.lines.length;
  const { data } = useQuery<{
    autoSpinsAmount: number;
    isStopOnAnyWin: boolean;
    isStopOnWinExceeds: boolean;
    isStopOnBalanceIncrease: boolean;
    isStopOnBalanceDecrease: boolean;
    stopOnWinExceeds: number;
    stopOnBalanceIncrease: number;
    stopOnBalanceDecrease: number;
  }>(getAutoSpinsSettingsGql);
  useEffect(() => {
    setAutoSpinsAmount(config.autoplay.autoSpinValues[3]);
  }, []);
  const [winExceedsSliderValue, setWinExceedsSliderValue] = useState(1);
  const [winIncreasesBySliderValue, setWinIncreasesBySliderValue] = useState(1);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);
  const balanceAmount = (userData?.user.balance.amount || 0) / 100;
  const betAmount = (dataBet?.betAmount || 1) * linesAmount;
  const {
    autoSpinsAmount,
    isStopOnAnyWin,
    stopOnWinExceeds,
    stopOnBalanceIncrease,
    stopOnBalanceDecrease,
    isStopOnWinExceeds,
    isStopOnBalanceIncrease,
    isStopOnBalanceDecrease,
  } = data!;

  const onAutoSpinsAmountChange = useCallback((value: number) => {
    setAutoSpinsAmount(value);
  }, []);

  const handleCloseAutoplay = useCallback(() => {
    AudioHowl.play({ type: ISongs.SFX_UI_Close });
    handleClose();
  }, [handleClose]);

  const handleStartAutoplay = useCallback(() => {
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    setAutoSpinsLeft(autoSpinsAmount);
    setIsAutoSpins(true);
    setAutoSpinsStartBalance(balanceAmount);
    handleClose();
  }, [balanceAmount, autoSpinsAmount, handleClose]);

  useEffect(() => {
    setStopOnWinExceeds(betAmount);
    setStopOnBalanceIncrease(betAmount);
    setStopOnBalanceDecrease(betAmount);
  }, [betAmount]);
  return (
    <div className={`${styles['autoplay-settings']} ${className}`}>
      <div className={styles.label}>{t('autoSpin')}</div>
      <div className="btn-group">
        {autoplay.options.map((item) => (
          <Button
            key={item}
            className={`${styles.btn} ${autoSpinsAmount === item ? styles.checked : ''}`}
            title={`${item}`}
            onClick={() => {
              AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
              onAutoSpinsAmountChange(item);
            }}
          />
        ))}
      </div>
      {autoplay.conditions.stopOnAnyWin.enabled && (
        <div className={styles['autoplay-settings__item']}>
          <Grid>
            <GridItem width="3" alignX="center">
              <Toggle
                className="toggle"
                checked={isStopOnAnyWin}
                id="stop_on_won"
                onChange={() => {
                  setIsStopOnAnyWin(!isStopOnAnyWin);
                  AudioHowl.play({ type: ISongs.SFX_UI_General });
                }}
              />
            </GridItem>
            <GridItem>
              <div className={styles.label}>{t('autoPlayStop')}</div>
            </GridItem>
          </Grid>
        </div>
      )}
      {autoplay.conditions.stopIfSingleWinExceeds.enabled && (
        <>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="3" alignX="center">
                <Toggle
                  className="toggle"
                  checked={isStopOnWinExceeds}
                  id="stop_on_win_exceeds"
                  onChange={() => {
                    setIsStopOnWinExceeds(!isStopOnWinExceeds);
                    AudioHowl.play({ type: ISongs.SFX_UI_General });
                  }}
                />
              </GridItem>
              <GridItem>
                <div className={styles.label}>{t('autoPlayStopSingleWin')}</div>
              </GridItem>
            </Grid>
          </div>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="4">
                <AutoPlaySettingsInput
                  disabled={!isStopOnWinExceeds}
                  value={`${formatNumber({
                    currency: userData?.user.balance.currency,
                    value: (stopOnWinExceeds * setCoinValue().variants[0]) / 100,
                  })}`}
                />
              </GridItem>
              <GridItem>
                <RangeSlider
                  disabled={!isStopOnWinExceeds}
                  className="range-slider"
                  min={1}
                  max={autoplay.conditions.stopIfSingleWinExceeds.multipliers.length}
                  value={winExceedsSliderValue}
                  onChange={(v) => {
                    setWinExceedsSliderValue(v);
                    setStopOnWinExceeds(autoplay.conditions.stopIfSingleWinExceeds.multipliers[v - 1] * betAmount);
                    AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
                  }}
                />
              </GridItem>
            </Grid>
          </div>
        </>
      )}
      {autoplay.conditions.stopIfBalanceIncreasesBy.enabled && (
        <>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="3" alignX="center">
                <Toggle
                  className="toggle"
                  checked={isStopOnBalanceIncrease}
                  id="stop_on_balance_increase"
                  onChange={() => {
                    setIsStopOnBalanceIncrease(!isStopOnBalanceIncrease);
                    AudioHowl.play({ type: ISongs.SFX_UI_General });
                  }}
                />
              </GridItem>
              <GridItem>
                <div className={styles.label}>{t('autoPlayStopBalanceUp')}</div>
              </GridItem>
            </Grid>
          </div>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="4">
                <AutoPlaySettingsInput
                  disabled={!isStopOnBalanceIncrease}
                  value={`${formatNumber({
                    currency: userData?.user.balance.currency,
                    value: (stopOnBalanceIncrease * setCoinValue().variants[0]) / 100,
                  })}`}
                />
              </GridItem>
              <GridItem>
                <RangeSlider
                  disabled={!isStopOnBalanceIncrease}
                  className="range-slider"
                  min={1}
                  max={autoplay.conditions.stopIfBalanceIncreasesBy.multipliers.length}
                  value={winIncreasesBySliderValue}
                  onChange={(v) => {
                    setWinIncreasesBySliderValue(v);
                    setStopOnBalanceIncrease(
                      autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[v - 1] * betAmount,
                    );
                    AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
                  }}
                />
              </GridItem>
            </Grid>
          </div>
        </>
      )}
      {autoplay.conditions.stopIfBalanceDecreasesBy.enabled && (
        <>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="3" alignX="center">
                <Toggle
                  className="toggle"
                  checked={isStopOnBalanceDecrease}
                  id="stop_on_balance_decrease"
                  onChange={() => {
                    setIsStopOnBalanceDecrease(!isStopOnBalanceDecrease);
                    AudioHowl.play({ type: ISongs.SFX_UI_General });
                  }}
                />
              </GridItem>
              <GridItem>
                <div className={styles.label}>{t('autoPlayStopBalanceDown')}</div>
              </GridItem>
            </Grid>
          </div>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="4">
                <AutoPlaySettingsInput
                  disabled={!isStopOnBalanceDecrease}
                  value={`${formatNumber({
                    currency: userData?.user.balance.currency,
                    value: (stopOnBalanceDecrease * setCoinValue().variants[0]) / 100,
                  })}`}
                />
              </GridItem>
              <GridItem>
                <RangeSlider
                  disabled={!isStopOnBalanceDecrease}
                  className="range-slider"
                  min={betAmount}
                  max={(balanceAmount * 100) / setCoinValue().variants[0]}
                  value={stopOnBalanceDecrease}
                  onChange={(v) => {
                    setStopOnBalanceDecrease(v);
                    AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
                  }}
                />
              </GridItem>
            </Grid>
          </div>
        </>
      )}
      <div className="btn-group">
        <button className={styles.playBtn} disabled={autoSpinsAmount === 0} onClick={handleStartAutoplay}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.buttonOk)} alt="playBtn" />
        </button>
      </div>
    </div>
  );
};

export default AutoPlaySettings;
