import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { EventTypes, ISettledBet } from '../global.d';
import {
  setIsRevokeThrowingError,
  setPrevReelsPosition,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql';
import i18n from '../i18next';
import { normalizeCoins } from '../utils';
import AnimationGroup from './animations/animationGroup';
import Animator from './animations/animator';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  APPLICATION_TRANSPARENT,
  eventManager,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  SlotMachineState,
  TIMEOUT_ERROR_MESSAGE,
} from './config';
import { Icon, ISlotData } from './d';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import Slot from './slot/slot';
import TintContainer from './tint/tintContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotData;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  public animator: Animator;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsContainer: ReelsContainer;

  public linesContainer: LinesContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public winSlotsContainer: WinSlotsContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      transparent: APPLICATION_TRANSPARENT,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.animator = new Animator(this.application);
    this.slotConfig = slotData;
    this.linesContainer = new LinesContainer(slotData.lines);
    const startPositions = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    setPrevReelsPosition(startPositions.slice(0, 5));

    setWinAmount(normalizeCoins(setUserLastBetResult().result.winCoinAmount));
    this.reelsContainer = new ReelsContainer(slotData.reels, startPositions);
    this.tintContainer = new TintContainer();
    this.winSlotsContainer = new WinSlotsContainer();
    this.miniPayTableContainer = new MiniPayTableContainer(
      this.getSlotById.bind(this),
    );
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
  }

  private initPixiLayers() {
    this.application.stage.addChild(new BottomContainer());
    this.application.stage.addChild(this.initSafeArea());
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: this.winSlotsContainer,
      linesContainer: this.linesContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    gameView.slotsContainer.on('mousedown', () => this.skipAnimations());
    gameView.slotsContainer.on('touchstart', () => this.skipAnimations());

    return gameView;
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REGISTER_ANIMATOR,
      this.registerAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR, TIMEOUT_ERROR_MESSAGE);
  }

  private handleError(message: string): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private registerAnimator(animator: () => void) {
    this.application.ticker.add(animator);
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.isReadyForStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        this.removeErrorHandler();
        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.round.reelPositions,
          this.getAnticipationReelId(this.nextResult.round.spinResult),
        );
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const animationGroup = new AnimationGroup();
      for (let i = 0; i < REELS_AMOUNT; i++) {
        const spinAnimation = this.reelsContainer.reels[i].createSpinAnimation(
          isTurboSpin,
        );
        if (i === 0) {
          spinAnimation.getFakeRolling().addOnChange(() => {
            if (this.nextResult && !this.isReadyForStop) {
              this.isReadyForStop = true;
              this.removeErrorHandler();
              eventManager.emit(
                EventTypes.SETUP_REEL_POSITIONS,
                this.nextResult.round.reelPositions,
                this.getAnticipationReelId(this.nextResult.round.spinResult),
              );
            }
          });
          spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
        }
        this.reelsContainer.reels[i].isPlaySoundOnStop = true;

        if (!this.nextResult) {
          if (i === REELS_AMOUNT - 1) {
            spinAnimation.addOnComplete(() =>
              eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
            );
          }
        }
        animationGroup.addAnimation(spinAnimation);
      }
      animationGroup.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private onCountUpEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        // eslint-disable-next-line no-plusplus
        if (spinResult[j].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

        if (currentCount >= count) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId;
  }

  private skipAnimations(): void {
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
  }

  public setResult(result: ISettledBet): void {
    this.nextResult = result;
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance);
    setPrevReelsPosition(result.round.reelPositions.slice(0, 5));
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.nextResult!.round.spinResult);
    if (this.nextResult?.round.paylines.length) {
      this.setState(SlotMachineState.WINNING);
      eventManager.emit(
        EventTypes.START_WIN_ANIMATION,
        this.nextResult!,
        isTurboSpin,
      );
    } else {
      this.setState(SlotMachineState.IDLE);
    }
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length -
        this.reelsContainer.reels[x].position +
        y -
        1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private onStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
    }
  }
}

export default SlotMachine;
