import { isMobile } from 'mobile-device-detect';

import { EventTypes } from '../../global.d';
import ViewContainer from '../components/container';
import { SAFE_AREA_DESKTOP_BOTTOM_PADDING, SAFE_AREA_MOBILE_BOTTOM_PADDING, eventManager } from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private resize(width: number, height: number): void {
    let newWidth;
    let newHeight;
    const bottomPadding = isMobile ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const bottomContainerEl = document.getElementById('bottomContainer');
    const bottomSpace = (bottomContainerEl?.offsetHeight || 40) + bottomPadding;

    if (width / height > 4 / 3) {
      newWidth = ((height - bottomSpace) * 4) / 3;
      newHeight = height - bottomSpace;
    } else {
      newWidth = width;
      newHeight = width * 0.75 - bottomSpace;
    }
    this.position.set(Math.max((width - newWidth) / 2, 0), Math.max((height - newHeight - bottomSpace) / 2, 0));
    this.width = newWidth;
    this.height = newHeight;
    this.scale.set(1, 1);
    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, newWidth, newHeight, this.position);
  }
}
export default SafeArea;
