import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import { IResource } from '@phoenix7dev/shared-components/dist/resources/d';

import variables from '../assets/styles/export.module.scss';
import { SlotId, config } from '../config';
import { EventTypes, ISettledBet, ISettledBet2 } from '../global.d';
import { setCoinValue, setSlotConfig, setStressful } from '../gql';
import { eventManager } from '../slotMachine/config';
import { ISlotData, ISlotData2, Icon } from '../slotMachine/d';

import { IPixiAssets } from './d';
import { getSpinResult } from './helper';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    PIXI.Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => PIXI.Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result: Array<PromiseRejectedResult | PromiseFulfilledResult<IResource>> = await Promise.allSettled(
        promises,
      );
      const failed = _.filter(result, (asset) => asset.status === 'rejected') as Array<PromiseRejectedResult>;

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = rejected.reason.source;
            asset.onload = () => {
              if (cb) cb(rejected.reason.key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        // eslint-disable-next-line no-continue
        continue;
      }
      success = true;
      break;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables.breakpointMobilePortraitMax, 10);

  return isPortrait && width <= maxWidth;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables.bottomHeightPercentMobilePortrait, 10) / 100);
  }
  return height * (parseInt(variables.bottomHeightPercent, 10) / 100);
};

export const apiV2ToApiV1GetSlots = (slot: ISlotData2): ISlotData => {
  const {
    id,
    icons,
    lines,
    reels,
    previewImage,
    settings: {
      startPosition,
      betLines: { max, min },
    },
    clientSettings: { coinValues },
  } = slot;
  const { coinCode } = setSlotConfig();
  const coinValue = coinValues.reduce((acc, { code, variants }) => {
    if (code === coinCode) {
      setCoinValue({
        code,
        variants,
      });
    }
    return {
      ...acc,
      [code]: {
        min,
        max,
      },
    };
  }, {} as ISlotData['settings']['playerSettings']['coinValue']);
  const configIcons = icons.reduce((acc, item) => {
    const combos = item.combos?.map((item) => ({
      ...item,
      multiplier: item.rewards[0].multiplier,
    }));
    return {
      ...acc,
      [item.id]: {
        id: item.id,
        type: item.type,
        combos,
        payoffType: item.payoffType,
      },
    };
  }, {} as { [key in SlotId]: Icon });
  const [{ layout }] = reels;
  const mapReels = layout.map((item) => item.map((i) => configIcons[i]));

  const oldIcons = icons.map(({ id }) => ({ ...configIcons[id] }));

  const data: ISlotData = {
    id,
    reels: mapReels,
    lines,
    icons: oldIcons,
    previewImage,
    clientSettings: slot.clientSettings,
    settings: {
      playerSettings: {
        betAmount: {
          min,
          max,
        },
        coinValue,
      },
      startPosition,
    },
  };
  return data;
};

const getAmount = (sum: number, paylines: number) => {
  if (paylines <= 1) {
    return [sum];
  }
  const num = paylines - 1;
  const amount = Math.floor(sum / num);
  const arr = new Array(num).fill(amount);
  const diffSum = sum - amount * num;
  arr.push(diffSum);
  return arr;
};

export const apiV2ToApiV1PlaceBet = (placeBet: ISettledBet2): ISettledBet => {
  const {
    bet: {
      id,
      coinAmount,
      coinValue,
      lineSet: { lines },
      createdAt,
      result: { reelPositions, winCoinAmount },
      reelSet,
      slotId,
    },
    balance: { placed, settled },
    paylines,
  } = placeBet;
  const spinResult = getSpinResult({
    reelPositions: reelPositions.slice(0, 5),
    reelSet,
    icons: setSlotConfig().icons,
  });
  const amountForPaylines = getAmount(winCoinAmount, paylines.length);
  const newPaylines = paylines.map((i, index) => ({
    ...i,
    amount: amountForPaylines[index],
  }));
  const data: ISettledBet = {
    id,
    coinAmount,
    coinValue: coinValue / setCoinValue().variants[0],
    lines,
    slotId,
    round: {
      id: reelSet.id,
      reelPositions,
      type: 'REGULAR',
      hasNext: false,
      createdAt,
      balance: settled,
      paylines: newPaylines,
      spinResult,
    },
    balance: placed,
  };
  return data;
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue().variants[0]): number => {
  return (coins * coinValue) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  creditMultiplier?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.creditMultiplier || 25)) / 100;
};

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(1, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export const fallBackReelPosition = (): void => {
  eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
  eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
};

export const queryParams = new URLSearchParams(window.location.search);

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i];

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};
