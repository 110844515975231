import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  Music_Theme_BaseGame_Loop_melo = 'Music_Theme_BaseGame_Loop_melo',
  Music_Theme_BaseGame_Loop_base = 'Music_Theme_BaseGame_Loop_base',
  Music_BigWin_End = 'Music_BigWin_End',
  Music_BigWin_Loop = 'Music_BigWin_Loop',
  SFX_WIN_BIG = 'SFX_WIN_BIG',
  SFX_WIN_EPIC = 'SFX_WIN_EPIC',
  SFX_WIN_GREAT = 'SFX_WIN_GREAT',
  SFX_WIN_MEGA = 'SFX_WIN_MEGA',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_SM_CountUp = 'SFX_SM_CountUp',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_UI_Hover = 'SFX_UI_Hover',
}

export const audioSprite: AudioSprite = {
  [ISongs.Music_BigWin_End]: [0, 3427.5056689342405],
  [ISongs.Music_BigWin_Loop]: [5000, 28444.44444444444, true],
  [ISongs.Music_Theme_BaseGame_Loop_base]: [35000, 32640, true],
  [ISongs.Music_Theme_BaseGame_Loop_melo]: [69000, 32640, true],
  [ISongs.SFX_SM_CountUp]: [103000, 399.79591836734585],
  [ISongs.SFX_SM_CountUp_End]: [105000, 933.4693877550961],
  [ISongs.SFX_SM_CountUp_Loop]: [107000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [109000, 320.47619047618525],
  [ISongs.SFX_UI_BetChange]: [111000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [113000, 200.68027210884054],
  [ISongs.SFX_UI_General]: [115000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [117000, 151.76870748298654],
  [ISongs.SFX_UI_MaxBet]: [119000, 122.92517006802939],
  [ISongs.SFX_UI_MenuOpen]: [121000, 277.1428571428629],
  [ISongs.SFX_UI_SpinStart]: [123000, 249.59183673469454],
  [ISongs.SFX_UI_SpinStop]: [125000, 151.02040816326223],
  [ISongs.SFX_WIN_BIG]: [127000, 1622.3356009070358],
  [ISongs.SFX_WIN_EPIC]: [130000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [133000, 2118.390022675726],
  [ISongs.SFX_WIN_MEGA]: [137000, 1696.1678004535088],
};

export const audioSpriteVolume = {
  [ISongs.Music_BigWin_End]: 0.3,
  [ISongs.Music_BigWin_Loop]: 0.3,
  [ISongs.Music_Theme_BaseGame_Loop_base]: 0.3,
  [ISongs.Music_Theme_BaseGame_Loop_melo]: 0.2,
  [ISongs.SFX_SM_CountUp]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.4,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
